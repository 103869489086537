



















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { EnumProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './individual-meter-water-meter-hot-lg.svg';

@Component
export default class IndividualMeterHotWaterMeterDevicePanel extends Mixins(SpotWidgetDataMixin) {
  @EnumProp('BLUE', 'RED')
  private readonly volumeColor!: string;

  private backgroundImage = bgImage;
}
